
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPageBlogPostLayout} from 'layouts/CmsPageBlogPostLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPageBlogPostLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    {
      /* TODO Inserire i chilometri fra gli step dello svolgimento */
    }
    <p>{`Quando ho bisogno di staccare e non ho niente di organizzato prendo la bici, esco di casa e inizio a pedalare per i colli in cerca di silenzio, natura e panorami suggestivi.`}</p>
    <p>{`Questo è il giro che percorro più spesso perché è `}<strong parentName="p">{`comodo al centro`}</strong>{` ed è anche `}<strong parentName="p">{`molto scenografico`}</strong>{`.`}</p>
    <p>{`Lungo queste `}<strong parentName="p">{`strade poco trafficate`}</strong>{` mi godo `}<strong parentName="p">{`viste spettacolari`}</strong>{` sulla città di `}<strong parentName="p">{`Vicenza`}</strong>{`, sulle `}<strong parentName="p">{`Prealpi Vicentine`}</strong>{` che le fanno da sfondo e sulla `}<strong parentName="p">{`Pianura Padana`}</strong>{` verso Padova. Mi capita anche di vedere qualche capriolo se sono fortunato e il traffico è assente.`}</p>
    <p>{`Il percorso è collinare. Inizia a `}<strong parentName="p">{`Vicenza in zona Gogna`}</strong>{`, sale a `}<strong parentName="p">{`Monte Berico`}</strong>{`, prosegue in cresta lungo la Dorsale dei Colli Berici passando per `}<strong parentName="p">{`Arcugnano`}</strong>{` e `}<strong parentName="p">{`Perarolo`}</strong>{`, scende a `}<strong parentName="p">{`S. Agostino`}</strong>{` e finisce attraversando in piano il quartiere dei `}<strong parentName="p">{`Ferrovieri`}</strong>{`.`}</p>
    <h2>{`Descrizione del percorso`}</h2>
    <p>{`Questo itinerario si svolge interamente su strade asfaltate. È quindi adatto a `}<strong parentName="p">{`bici da corsa`}</strong>{`, `}<strong parentName="p">{`mountain bike`}</strong>{` e `}<strong parentName="p">{`gravel`}</strong>{`.`}</p>
    <p>{`Queste strade sono quasi tutte ben conservate. A S. Agostino e lungo il quartiere dei Ferrovieri il fondo stradale è però rovinato da numerose buche e crepe.`}</p>
    <h2>{`Svolgimento del percorso`}</h2>
    <p>{`Affrontiamo subito il tratto più difficile di questo itinerario salendo verso `}<strong parentName="p">{`Monte Berico`}</strong>{`. È una salita molto ripida, ma per fortuna breve.`}</p>
    <p>{`Monte Berico ospita il `}<strong parentName="p">{`Santuario della Madonna di Monte Berico`}</strong>{`, costruito in seguito alle apparizioni della Madonna avvenute nel 1426 d.C. e nel 1428 d.C.`}</p>
    <p>{`Oserei dire che è obbligatorio affacciarsi alla `}<strong parentName="p">{`terrazza di Piazzale della Vittoria`}</strong>{`: godremo di una Vicenza vista dall’alto e, nelle giornate limpide, rimarremo stupiti di fronte al `}<strong parentName="p">{`meraviglioso panorama`}</strong>{` che ci mostra la parte nord-orientale della Provincia con le sue `}<strong parentName="p">{`montagne`}</strong>{`.`}</p>
    <p>{`Passiamo poi per il `}<strong parentName="p">{`Museo del Risorgimento e della Resistenza a Villa Guiccioli`}</strong>{`, che ospita anche un parco, e proseguiamo con lievi saliscendi.`}</p>
    <p>{`Dopo la `}<strong parentName="p">{`località di Villa Margherita`}</strong>{` scendiamo bruscamente per circa 1 km.`}</p>
    <p>{`La prossima salita, ripida ma anch’essa breve, ci porta ad `}<strong parentName="p">{`Arcugnano`}</strong>{`. Questo è il secondo tratto impegnativo di questo itinerario.`}</p>
    <p>{`Continuiamo serpeggiando in piano per qualche chilometro.`}</p>
    <p>{`Dalla `}<strong parentName="p">{`località di Spianzana`}</strong>{` affrontiamo l’ultima delle tre salite impegnative del giro che, ancora serpeggiando, ci porta alla frazione di `}<strong parentName="p">{`Perarolo`}</strong>{`.`}</p>
    <p>{`A `}<strong parentName="p">{`Perarolo`}</strong>{` ci possiamo rinfrescare alla `}<strong parentName="p">{`fontana di fianco al monumento ai caduti`}</strong>{`. Questo è un ottimo luogo per fare pausa prima di scendere dai colli.`}</p>
    <p>{`Cambiamo direzione dopo qualche decina di metri `}<strong parentName="p">{`seguendo le indicazioni per Brendola`}</strong>{`, imboccando la `}<strong parentName="p">{`Strada di Perarolo SP-129`}</strong>{`. Proseguiamo solo per qualche centinaia di metri prima di svoltare a destra in Via Paolini, seguendo le `}<strong parentName="p">{`indicazioni per Vicenza e S. Agostino`}</strong>{`.`}</p>
    <p>{`Scendiamo quindi rapidamente verso la frazione di `}<strong parentName="p">{`S. Agostino`}</strong>{`. La discesa è molto ripida, e dobbiamo prestare estrema attenzione perché `}<strong parentName="p">{`le curve sono molto strette`}</strong>{`.`}</p>
    <p>{`Continuiamo ora in piano e senza difficoltà per gli ultimi chilometri, passando per il `}<strong parentName="p">{`quartiere dei Ferrovieri`}</strong>{`. Questo è l’unico tratto del percorso dove dobbiamo stare attenti al traffico.`}</p>
    <h2>{`Difficoltà maggiori`}</h2>
    <ul>
      <li parentName="ul">{`salita verso Monte Berico`}</li>
      <li parentName="ul">{`salita verso Arcugnano`}</li>
      <li parentName="ul">{`salita verso Perarolo`}</li>
      <li parentName="ul">{`curve strette nella ripida discesa verso S. Agostino`}</li>
      <li parentName="ul">{`traffico nelle ore di punta lungo il tratto finale del percorso`}</li>
    </ul>
    <h2>{`Punto di partenza`}</h2>
    <p>{`Il `}<strong parentName="p">{`piazzale di fronte la chiesa di S. Giorgio`}</strong>{` in zona Gogna, a Vicenza, è molto comodo perché qui si può parcheggiare l’auto gratuitamente. Si può parcheggiare l’auto gratuitamente anche lungo la strada.`}</p>
    <p>{`Nel `}<strong parentName="p">{`quartiere dei Ferrovieri`}</strong>{` i parcheggi sono gratuiti.`}</p>
    <p><strong parentName="p">{`Piazzale della Vittoria`}</strong>{` ospita un grande parcheggio gratuito, che però è impraticabile durante le celebrazioni delle Sante Messe. Iniziando da qui ci si troverà a percorrere la salita verso Monte Berico alla fine del percorso.`}</p>
    <h2>{`Luoghi di interesse`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://it.wikipedia.org/wiki/Santuario_della_Madonna_di_Monte_Berico",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Santuario della Madonna di Monte Berico`}</a></li>
      <li parentName="ul">{`Piazzale della Vittoria`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.comune.vicenza.it/cittadino/scheda.php/42724,218646",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Museo del Risorgimento e della Resistenza a Villa Guiccioli`}</a></li>
      <li parentName="ul">{`parco di Villa Guiccioli`}</li>
    </ul>
    <h2>{`Punti di appoggio`}</h2>
    <ul>
      <li parentName="ul">{`bagni pubblici gratuiti di Piazzale della Vittoria a Monte Berico`}</li>
      <li parentName="ul">{`un paio di bar a Monte Berico`}</li>
      <li parentName="ul">{`qualche ristorante e pizzeria tra Arcugnano e Perarolo`}</li>
      <li parentName="ul">{`acqua potabile vicino al monumento ai caduti a Perarolo`}</li>
    </ul>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    